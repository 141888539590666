import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EsGuidesBeginnerGuide: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_beginner.jpg"
            alt="Introduction"
          />
        </div>
        <div className="page-details">
          <h1>Eversoul Beginner's Guide</h1>
          <h2>All you need to know about Eversoul.</h2>
          <p>
            Last updated: <strong>15/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Characters" />
        <StaticImage
          src="../../../images/eversoul/promo_3.webp"
          alt="Characters"
        />
        <p>
          Characters in Eversoul belong to one of 7 factions: Humans, Beasts,
          Fairy, Undead, Angel, Demon or Chaos. While the first 4 factions are
          part of the classic 'elemental' wheel and they counter each other,
          Angel and Demon are this game's Light/Dark faction and finally Chaotic
          are similar to Angel/Demon in ascension requirements but differ in
          that they are accessible only on certain banners. They are rarer and
          harder to raise up, but provide you with some unique benefits. For
        </p>
        <p>
          For more information on Soul Classes, Standout souls and commonly used
          terms, check out this guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/eversoul/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_intro.jpg"
                alt="Introduction"
              />
            }
          />
        </Row>
        <StaticImage
          src="../../../images/eversoul/generic/team_counters.webp"
          alt="Rarities"
        />
        <p>
          Also, each character that you can obtain in the game belongs to one of
          three rarity tiers: common, rare and epic.
        </p>
        <ul>
          <li>
            <strong>Common characters (green)</strong> will be rarely used even
            at the start of the game and will be discarded for a currency that
            you can use in a shop,
          </li>
          <li>
            <strong>Rare characters (blue)</strong> will be used in the early
            game, but Epic characters will soon start replacing them. Still,
            Rare characters have an important function - they can be upgraded to
            Epic rarity (and higher) and then be used as fodder to upgrade the
            rarity of your Epic characters,
          </li>
          <li>
            <strong>Epic characters (purple)</strong> will form the core of your
            team once you obtain them, but getting a single copy of them isn't
            enough. Why? We will explain below.
          </li>
        </ul>
        <p>Each character also has 5 skills:</p>
        <ul>
          <li>
            <strong> Ultimate</strong> - the strongest skill of a character, but
            all characters in the team share the ultimate bar, so you have to
            decide who should use the Ultimate skill (and on auto-battle, you
            can set-up the priority),
          </li>
          <li>
            <strong>Active</strong> - the active skill can be used once a
            character's MP reaches 100%. It's not as impactful as the Ultimate,
            but each character in the team will use their Active skill the
            moment it becomes available (or you can use them manually too),
          </li>
          <li>
            <strong>Sub</strong> - there are 2 sub skills that are used
            automatically by the character based on their rotation, Every
            character has different auto attacks and sub skill rotation it
            follows,
          </li>
          <li>
            <strong>Passive</strong> - and lastly, there's a passive skill that
            either affects the character itself or her allies/enemies.
          </li>
        </ul>
        <SectionHeader title="Progression" />
        <p>
          In idle games, the starter rarities are only the beginning. As you can
          see on the image below, there are way more background colors than just
          the 3 mentioned above:
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/guide_battle.webp"
          alt="Rarities"
        />
        <p>
          These colors respond to character rarity as well as their ascension
          Level, As already discussed above - Green/Blue/Purple represent basic
          Common/Rare/Epic souls, after that you have Orange, Red and Pink color
          for characters - representing their ascension at Legendary, Eternal
          and Origin Realms, while a Star Denotes + Realm, for example a Purple
          character with star corresponds to Epic + whereas a Red character with
          star corresponds to Eternal +.
        </p>
        <p>
          To reach the higher rarity tiers you will need two things:{' '}
          <strong>copies of the same character and fodder</strong>. Fodder will
          be obtainable from combining Rare characters with each other, but to
          get more copies of the same character, you will have to pull on the
          banners. A lot. Usually, some characters are also available in the
          in-game shops that are tied to some game modes, to make it easier to
          raise them, but pulling will be the main source of dupes.
        </p>
        <p>
          Also this is why, just having one copy of an Epic character isn't
          enough and you will need to focus on a few characters and form the
          main team, rather than spreading your resources.{' '}
          <strong>
            That's because the level cap of characters will increase with the
            rarity
          </strong>
          . And getting stuck at the level cap will drastically hinder your
          progress. We will give additional tips in regard to progress in a
          dedicated guide later.
        </p>
        <p>
          Contrary to the norm in idle games where as a F2P player you should
          never invest into the Light/Dark factions due to lower pull rates,
          Eversoul has same pull rates for such characters in their respective
          pickups, allowing them to be invested easily by F2P (even if they have
          more copies required to reach Origin), this is further boosted by the
          fact that you can get L/D characters from any banner in the game
          including friendship pulls and epic shards!
        </p>
        <SectionHeader title="Team building" />
        <p>
          The team in Eversoul consist of 5 characters and similar to other
          games, you will obtain additional stats when you use more characters
          from the same faction. For detailed information on the same, check out
          our Beginner's Guide linked below and for commonly used teams in early
          as well as end game check out our Team Building Guide linked below.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/eversoul/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_intro.jpg"
                alt="Introduction"
              />
            }
          />
          <CategoryCard
            title="Team building"
            link="/eversoul/guides/team-building"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Combat" />
        <p>
          The combat in Eversoul uses full models of the characters unlike the
          chibis most idle games utilize. You can manually control when to use
          Main and Ultimate skills, or let AI handle it completely in Auto mode.
          You can see how it looks in action on the video below:
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="QpCctBEjqT0" />
          </Col>
        </Row>
        <SectionHeader title="Game Modes" />
        <p>
          Eversoul features a variety of game modes ranging from Single Player
          PvE to Competitive PvE as well as PvP content.
        </p>
        <p>
          However, a lot of these modes are unlocked as you progress further
          into game, for details on which mode is unlocked when, check out
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Campaign unlocks"
            link="/eversoul/guides/campaign-unlocks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_progress.png"
                alt="Campaign unlocks"
              />
            }
          />
        </Row>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_1.webp"
          alt="Game Modes 1"
        />
        <h5>Main story</h5>
        <p>
          Eversoul’s story is mostly a dialogue and slideshow type, with some
          basic dialogue input choices from you, the player. The story itself is
          surprisingly detailed for an idle game, and rewards you for going
          through each part. It also includes some dungeon-like stages (which
          become Hall of Memories content once you’ve obtained the mode) where
          you navigate through a map, interact with NPCs, solve puzzles, and
          fight enemies.
        </p>
        <h5>Battlefront</h5>
        <p>
          The main content of the game where you fight multiple chapters of
          content, with latest chapters having stages beyond 50 per chapter.
          Battlefront is the primary PVE content in game, which starts with
          requiring 1 Team of 5 souls but changes to 2 teams of 5 souls and
          ultimately 3 Teams of 5 souls as you progress through the chapters,
          making it a considerable challenge and diversifying your investments.
        </p>
        <p>
          Clearing battlefront is the primary method to increase your hourly
          generation of Idle Resources, making it important to push as far as
          possible, as soon as you can. However, unlike most games, there is no
          starring system in place for stages in this mode, allowing you to win
          fights with most of your team dead and move on without issues.
        </p>
        <h5>Battlefront - Challenge Mode</h5>
        <p>
          In Challenge Mode, the fights are a similar 5v5 format to regular
          Battlefront, however there are less stages to clear per map (starting
          at only 2 in the first area) and each stage has different conditions.
          The first thing to note is that there is a level cap enforced on your
          team, so that regardless of what your level is, your characters will
          not exceed the cap, making it harder to brute force the stages.
          Second, there are effects that will buff the enemies, hinder your
          team, or both, requiring you to strategize around these effects to
          avoid losing. Third, there are win conditions which give different
          rewards, though they don’t all have to be done at once, nor do they
          all have to be done to progress to the next stage. Winning the battle
          is always one of the 3 requirements, which is enough to allow you to
          progress, however most of the rewards are worth gaining, so even if
          you have to change your team up and do the battle 3 separate times to
          clear each challenge, it’s often worth the time spent doing so.
        </p>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_2.webp"
          alt="Game Modes 2"
        />
        <h5>Faction Gates and Depths</h5>
        <p>
          Gates are the one of contents new users will focus most on, alongside
          Battlefront, as it allows you to gain a lot of resources (including
          everstones!) very quickly, helping you progress more in other content
          and pull more. There are 5 Gates in the game, Unlimited Gate which is
          much like battlefront where you can use any 5 souls and beat the stage
          (this goes to stage 400) as well as 4 Faction Gates, where you can
          only use 5 souls of the said Faction (This goes to stage 200 per
          Gate).
        </p>
        <p>
          After clearing Unlimited Gate 400 and Faction Gates 200, you will get
          access to Gate Depths content, which is the same as before but with
          more teams. Unlimited Gate will have you use 3 teams, and Faction
          Gates you will need to use 2 teams, still only using that Faction’s
          characters. Furthermore, in Depths enemies are equipped with their own
          Artifacts and will use Ultimate skills, making it the hardest PVE
          content in-game.
        </p>
        <h5>Dual Gates/Decoy Operation</h5>
        <p>
          Dual Gates is a game mode geared to farm Mana Crystals, one of the
          biggest bottlenecks in character levels. Always clear the highest
          level you can, preferably without hires, so you can unlock the stage
          to sweep it (using hires won’t allow you to sweep). An important thing
          to note is that you don’t have to do each difficulty along the way,
          you can skip to whichever difficulty you want and attempt it, if you
          fail, you can just reset the difficulty and try a lower one.
        </p>
        <p>
          Eventually, this mode will be replaced by Decoy Operation (as shown
          above in picture), which will allow you to choose your target resource
          between Mana Crystal, Mana Dust, Gold and Keepsake Enhance stones per
          entry. Decoy Operation changes your number of daily entries to 3, and
          you are able to select different options for each entry if you wish.
          (Already replaced in NA/EU and Asia, Pending in JP)
        </p>
        <h5>Dimensional Labyrinth</h5>
        <p>
          The staple of the Idle genre, Labyrinth in Eversoul resets every 2
          days (so 15 times a month). You have to pick your path and progress
          through the Labyrinth while both fighting stronger and stronger
          enemies and trying to keep your team alive, as HP won't regenerate
          here between fights. There are also bonus spaces that can provide
          extra buffs scattered throughout. Ultimately, you fight a raid boss
          for bonus prizes - such as more Labyrinth exclusive currency for its
          shop, Everstones and idle resources. The boss can be a particularly
          difficult encounter, though its lost HP won’t regenerate as you throw
          new teams at it, allowing you to eventually clear it if you have
          enough damage. As a consolation, players can sweep the stage they have
          successfully cleared (without revives) and get the rewards for the
          cleared difficulty in just a click.
        </p>
        <h5>Hall of Memories</h5>
        <p>
          Hall of Memories is a dungeon-like mode where you have to traverse
          through a map, find treasures, and solve puzzles, much like Main Story
          mode. (In fact, it is the dungeons from Story mode!) The dungeons
          rotate weekly and offer a considerable amount of Tickets and Idle
          resources as a reward.
        </p>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_4.webp"
          alt="Game Modes 4"
        />
        <h5>Arena</h5>
        <p>
          The PvP mode of Eversoul, where you select 5 characters to form your
          attack and defense teams and choose the opponent to attack. The fights
          are completely automatic and as such you can skip them from the attack
          screen itself, making them go very fast. Since it's fully automatic,
          positioning and choosing counter souls to the enemy is the important
          part here. But eversoul being the generous game it is, allows you to
          infinitely refresh your opponents every 5 seconds, letting you fish
          for easy matches for maximum score gains (supplemented by the fact
          that many players use weak defense teams on purpose to allow others to
          win easily).
        </p>
        <h5>Champions Arena</h5>
        <p>
          Another PvP mode, similar to Arena, but with 3 teams instead, and with
          addition of treasures which you can set per team. You need to win 2
          out of 3 matches to win these. The difference here is that it is not
          point-based ranking like regular Arena, but a rank swap with the
          winner taking the loser’s rank and sending them down to their old
          rank.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to PVP"
            link="/eversoul/guides/introduction-to-pvp"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_pvp.webp"
                alt="PVP Guide"
              />
            }
          />
        </Row>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_5.webp"
          alt="Game Modes 5"
        />
        <h5>Evil Soul Subjugation</h5>
        <p>
          Evil Soul Subjugation, or ESS for short, is a server-wide (Asia or
          NA/EU) competitive content in the style of Guild Raid, except it has a
          fixed HP pool depending on level of boss you choose (Level 100 to
          400+) and you can use up to 9 Teams (45 souls) to whittle it down.
          Ranking is determined by the highest level cleared, followed by least
          amount of teams used, followed by least time spent on the final fight.
          There are 2 character exclusive Skins as reward given per season of
          ESS - which are fairly easy to get even as F2P, especially the first
          skin.
        </p>
        <h5>Guild Raid</h5>
        <p>
          The main guild content in the game, Guild Raids feature an endless HP
          boss you aim to deal as much damage as possible to it with your three
          teams combined with Unique Treasure per team. (Shown just above 1, 2 ,
          3 Numbers in picture above). Guild Rankings are determined by
          cumulative damage of Guild members (only highest damage per person
          counts). Primarily a 5vs1 content, it gives advantage to ST dealers
          (exception being the Dark Knight boss, which is a mix of 5v1 and 5v5).
          Rewards include a generous amount of Everstones and Guild Currency -
          Useful for buying gear.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Guild Raids Explained"
            link="/eversoul/guides/guild-raids-explained"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_charite.jpg"
                alt="Guild Raids Explained"
              />
            }
          />
        </Row>
        <h5>World Boss</h5>
        <p>
          The latest addition, World Boss is a game mode similar to bosses seen
          in other games, where the progression of the fight is server-wide
          rather than an individual effort. There is still a ranking system for
          rewards based on your highest single damage contribution, but the
          boss’ total HP and progression reward is shared across all players in
          the server. This mode also introduced a new feature; the Partner Soul
          system. Each soul on your main team of 5 can have up to 2 other souls
          partnered up with them - a Main and a Sub Partner. The Main skill is
          unique to each soul, and provides some significant boosts to their
          partner, or even the whole team. Sub Partner skills are based upon
          soul Role groups, so for example, all souls classified as CC will
          provide the same buff, while all souls classified as Assassin will
          provide a different one. These are only applied to the soul they are
          partnered with, and are all an assortment of basic stat boosts. (You
          can check out Partner Skills on respective Soul’s character page on
          the website).
        </p>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_3.webp"
          alt="Game Modes 3"
        />
        <h5>Tower of Origin</h5>
        <p>
          Tower of Origin is a game mode that provides extra backstory on the
          featured soul, allowing players to learn a lot of their lore. It is
          standard 5v5 content in the same vein as Battlefront and Gates. Its
          rewards include 300 Artifact Shards of the featured character, a
          character exclusive Statue to place in Town, lots of other currencies
          and tickets, as well as a flash deal which is purchasable with real
          currency, allowing you to get a copy of the character and additional
          100 artifact shards (which is extra worth on Towers of Angel, Demon or
          Chaotic Faction characters).
        </p>
        <h5>Operation Eden Alliance</h5>
        <p>
          Operation Eden Alliance is another server-wide PvE competitive
          content. It is divided into 2 separate parts - Daily Operations and
          Infinite Operations. This content is primarily centered around
          clearing the stages while choosing certain or all conditions from the
          plethora of options available (as shown in the picture above).
        </p>
        <p>
          Daily Operations allows you to complete missions to farm tickets used
          to unlock the higher rarity conditions in Infinite Operations, while
          giving some rewards.
        </p>
        <p>
          Infinite Operations on other hand are where you compete with other
          players in terms of highest level cleared, which is affected by the
          amount and rarity of conditions you choose, highest levels can go
          upwards of 700+ while imposing severe restrictions on your team, or
          significant buffs to enemy souls. Unlike other rankings, this one is
          simply based on your cleared level - if you do maximum level, you’ll
          get the same reward as everyone else who also clears maximum, then
          rankings below will go down based on the amount of people who cleared.
          Its rewards include a generous amount of Everstones and tickets.
        </p>
        <h5>Town</h5>
        <StaticImage
          src="../../../images/eversoul/master/town.webp"
          alt="Town"
        />
        <p>
          The first really unique thing in Eversoul - you will be able to build
          and decorate your own Town and some of the buildings will even impact
          your account - whether it is increasing Idle resources gained per
          minute, or giving additional stats such as 10% Attack or 15% HP to
          characters housed in manors (such as shown in picture above).
        </p>
        <p>
          You can also hunt monsters in your town for some drops with low chance
          of it being summon tickets, as well as clear 3 friend towns (4 with
          Sharinne sub) for similar drops and 10 friendship points per person.
          Check the video below for a quick look into the Town.
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="dWwUz6605GA" />
          </Col>
        </Row>
        <SectionHeader title="Bond system" />
        <StaticImage
          src="../../../images/eversoul/master/bonds.webp"
          alt="Bonds"
        />
        <p>
          The Bond system in Eversoul allows you to converse with your
          characters, give them gifts and learn more about them individually by
          doing their Bond Stories. Also, if you get the perfect ending in their
          Bond Story, you will obtain their exclusive skin that changes their
          appearance in battle and their 2D art (however, some older skins don’t
          have new 2D art yet). Furthermore, characters with perfecting ending
          unlocked give additional gifts when outing and getting 2 red heart
          response (including Normal Summon Tickets) and during events such as
          Valentines, Christmas and few other in-game ones, they will give you a
          special gift as shown in the image above that hold plethora of
          rewards. In Eversoul, you can't buy skins, you get them all for free
          through Bonds and in-game events!
        </p>
        <p>
          The Bond System was recently revamped, allowing you to get souls to
          Level 40 Bond, with each level from Level 11-40 increasing their stats
          (Attack, HP, Defense) or Reducing the time taken by souls in
          completing part time jobs or resting.
        </p>
        <SectionHeader title="Shops and Currency" />
        <p>
          With all the game modes present, it naturally leads to lots of in-game
          currencies and various rewards they offer. This section will be
          discussing the same and it can be divided into 3 main parts - Gacha
          Resources, Idle Resources and Other Currencies. To know approximate
          monthly income for lot of them check out our Monthly Income guide
          below
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Monthly resource income (F2P)"
            link="/eversoul/guides/monthly-income"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_income.png"
                alt="Monthly resource income (F2P)"
              />
            }
          />
        </Row>
        <h5>Gacha Resources</h5>
        <StaticImage
          src="../../../images/eversoul/master/pulls_1.webp"
          alt="Pull Currencies 1"
        />
        <h5>Everstones</h5>
        <p>
          The primary currency of Eversoul, which is used to summon characters
          and their Artifacts, buy extra entries in events, and certain items
          from shops. The currency is divided into Free and Paid - Where paid
          currency can be used to do 1 discounted pull per day on character and
          Artifact gacha, and a one time 10 pull for guaranteed SSR. These can
          be obtained from various in-game events, normal progression, or
          purchased from the Cash Shop in various packages with real money.
        </p>
        <h5>Pick-Up Summon</h5>
        <p>
          Pick-Up Tickets (Gold) are used for any Rate Up banners currently
          available. A multi costs either 2100 Everstones or 10 Tickets. These
          are obtained from Pity milestones on pulling Rate Up banners (10 on 40
          Pulls, additional 20 on 160 pulls) or through real life currency
          purchases in the Cash Shop. 4% Rate for Epic Characters, with 1.6% for
          the featured character including Angel/Chaotic/Demon.
        </p>
        <h5>Erika’s Alchemy</h5>
        <p>
          Erika’s Alchemy Tickets (Purple) are used to pull on Erika’s Alchemy
          Banner (some of you who have played idle games prior to this, might
          remember it as Stargaze banner). This is a banner different from
          normal ones, as instead of pulling characters or Artifacts like
          normal, you choose a designated character as target of your pulls, and
          the pulls can result from anywhere between character of your choosing
          or idle resources or even tickets to other banners. This banner
          features Pity of 70, with each 10 pull costing 4000 Everstones or 10
          Tickets normally. An advanced banner meant for late game users or
          whales to quickly max a desired character, but generally should be
          used on Chaotic characters, or Angel/Demon ones until they are added
          to game as these tickets are rare and pulls are expensive.
        </p>
        <StaticImage
          src="../../../images/eversoul/master/pulls_2.webp"
          alt="Pull Currencies 2"
        />
        <h5>Normal Summon</h5>
        <p>
          Normal Summon Tickets (Blue) are used to summon on Normal Summon
          Banner. A multi costs either 2100 Everstones or 10 Tickets. You get 1
          free pull daily and you can do 1 discounted pull daily using paid
          everstones It also features 4% Rate for Epic Souls however the rate
          for Angel/Demon characters are 1/5th of the normal epic characters.
          and has built in pity at 30 pulls where you are guaranteed one epic
          soul randomly. These tickets are gained from multiple sources in-game
          or through real life currency purchases in the shop.
        </p>
        <h5>Type Summon</h5>
        <p>
          Type Summon Tickets (Green) are used to summon on Type Summon, These
          banners also have 4% Epic Rate but only give the characters of
          featured faction of the day, which rotates daily in order of
          Human→Beast→Fairy→Undead. A multi costs 10 Faction Tickets. These
          tickets are much more sparse to gain be it from in-game events or
          through real life currency purchases in the shop.
        </p>
        <h5>Artifact Summon</h5>
        <p>
          Artifact Summon Tickets (Red) are used to summon on the Artifact
          Summon Banner. A multi costs either 600 Everstones or 5 Tickets. They
          are gained from multiple sources in-game (primarily Gachapon event) or
          through real life currency purchases in the shop.
        </p>
        <h5>Friendship Summon</h5>
        <p>
          Obtained from sending and receiving hearts to and from your friends
          daily, clearing their towns of monsters and special mails on character
          birthdays. Friendship points are used to pull on Friendship summon,
          but unlike the majority of other games where these banner pools are
          limited and with lower rates, Eversoul sets itself apart by granting
          the banner full 4% Rate Up for all Epic characters, (even new releases
          get added to the pool on the first day of their release!) and even
          includes Angel and Demon characters, making it unprecedented in the
          genre. A multi costs 100 Friendship Points.
        </p>
        <h5>Idle Resources</h5>
        <h5>Mana Dust and Mana Crystals</h5>
        <p>
          Used to level up characters, these are the main Idle resources used in
          game. Earned from plethora of in-game events and normal progression.
          Mana crystals are in addition used to increase the level of the
          Overclock system.
        </p>
        <h5>Gold</h5>
        <p>
          Similarly to Mana Dust and Mana Crystal, Gold is used for level up of
          characters, but in addition to this, Gold is used to level up
          Keepsakes (Gear) as well as buy items in certain shops.
        </p>
        <h5>Other Currencies and Shops</h5>
        <StaticImage
          src="../../../images/eversoul/master/shop_1.webp"
          alt="Shop 1"
        />
        <h5>Memory Shards</h5>
        <p>
          Primarily obtained from releasing various normal souls you pull
          throughout the game, this shop is a good source of obtaining Epic
          Shards on a weekly basis, giving you some extra return on your pulls.
          While you can also release Rare and Epic souls for these shards, it is
          not recommended to do so, unless you are a massive spender with
          everything close to being maxed already. (Weekly Refresh)
        </p>
        <p>
          This currency is also used to purchase a one time dupe of new Chaotic
          souls from the Awakening Shop.
        </p>
        <h5>Labyrinth Coins</h5>
        <p>
          Exclusively earned from clearing Dimensional Labyrinth every two days,
          this shop is the primary source of getting Adrianne (Angel) dupes at a
          cheap price and getting her to Origin over time. Late game users can
          also use it to get Epic Shards or Mana Crystals once their Adrianne is
          Ascended enough.(Monthly Refresh)
        </p>
        <p>
          This currency is also used to purchase a one time dupe of new Chaotic
          souls from the Awakening Shop.
        </p>
        <h5>Arena Coins</h5>
        <p>
          The PVP currency obtained from fighting daily in Arena as well as
          Champions Arena, and their weekly ranking rewards. This currency
          should be primarily used to obtain Ayame (Demon) copies as she is
          quite useful in many game modes. Late game users also can use it to
          get Epic Shards once their Ayames are Ascended enough, or other
          dupes.(Monthly Refresh)
        </p>
        <p>
          This currency is also used to purchase a one time dupe of new Chaotic
          souls from the Awakening Shop.
        </p>
        <h5>Awakening Shop</h5>
        <p>
          An exclusive shop added to the game, post-release of the first Chaotic
          Soul as well as Awakened Soul - Catherine Radiance. This shop is used
          to buy one time dupes of the soul after you acquire it from its Rate
          Up banner, using various currencies. (No Refresh).
        </p>
        <StaticImage
          src="../../../images/eversoul/master/shop_3.webp"
          alt="Shop 3"
        />
        <h5>Raid Coins</h5>
        <p>
          The primary reward of Guild Raid and World Raid content, Raid Coins
          are used to buy Eternal/Eternal+ Keepsakes or Origin grade Molds. For
          the most part, these coins allow you to gather complete sets of
          Keepsakes for your souls with much more ease as the set and gear piece
          available on sale changes every day on reset. Fairly easy to gain as
          Guild and World Raids offer high rewards. (Daily Refresh)
        </p>
        <h5>Artifact Coins</h5>
        <p>
          These are obtained from Hall of Memories clears and some part-time
          jobs, Artifact Coins allow you to purchase various Artifact enhancing
          stones as well as 10 shards of the Artifacts on display. Purchase
          limits and Artifact Shards on display are refreshed every reset. The
          main focus here is buying Shards for the characters most important in
          your teams, followed by Artifact Stone of Primordium. (Daily Refresh)
        </p>
        <h5>Evil Soul Coin</h5>
        <p>
          This currency can be obtained from Evil Soul Subjugation exclusively
          and is used to primarily buy Mold Chests for Origin Keepsakes. (Weekly
          Refresh)
        </p>
        <h5>Operation Coin</h5>
        <p>
          Obtained exclusively from Operation Eden Alliance (both Daily
          Operations and Infinite Operations). This currency is the primary
          source for the Ark Enhance System, used to raise stats of all souls,
          or type groups of souls. Players should aim to buy all Main Enhance
          Circuit and Enhance Circuit : Type they can buy every month with
          priority on which type has the most DPS for them. (Monthly Refresh)
        </p>
        <StaticImage
          src="../../../images/eversoul/master/shop_2.webp"
          alt="Shop 2"
        />
        <h5>General Shop</h5>
        <p>
          Primarily used to buy Mana Crystals and Ark Enhance Circuits daily for
          Gold.
        </p>
        <h5>Antique Shop</h5>
        <p>
          Used to buy Costumes (3000 Everstones) and Furniture from various past
          events that have already had at least one rerun.
        </p>
        <h5>Town Coins</h5>
        <p>
          Earned from doing part-time jobs or daily town-clearing missions, Town
          Coins are the primary currency on which your town thrives, as you
          require them to not only buy part-time job buildings, but also various
          manors which increase HP/ATK/DEF of your soul placed inside them.
          Farming these coins is an important part of your daily cycle. At super
          late game, when you have all the required Epic/Rare part-time job
          buildings as well as all Large and Medium Manors, these coins are best
          used on daily purchase of Eternity Flower and Premium Gift Capsules to
          increase bond levels of your souls. (Daily Refresh)
        </p>
        <SectionHeader title="Character Progression" />
        <p>
          With all the game modes and shops, a natural thought comes to mind on
          how to make your characters stronger for them. Eversoul has multiple
          ways to increase your character and team’s Combat Power, allowing you
          to push further or score higher. They are as follows
        </p>
        <h5>Character Levels</h5>
        <p>
          The primary method to make your characters stronger is leveling them
          up. This requires Mana Dust, Mana Crystals and Gold. Every 20 levels
          (So level 21, 41, 61 and so on) your character gets an extra stat
          boost, so it's generally recommended to keep your Main DPS 20 levels
          higher than the sync to facilitate more damage and faster clears, at
          least up until you unlock the Overclock function. Character Levels
          also unlock skill upgrades, ranging from their Subs to Ultimate
          skills, until level 201 which provides their last upgrade. Leveling
          characters has another bottleneck of character Ascension, which will
          be discussed below (only applies to your 5 primary characters,
          characters in sync can bypass their Ascension level cap).
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Level requirements"
            link="/eversoul/guides/level-requirements"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_level.png"
                alt="Level requirements"
              />
            }
          />
        </Row>
        <h5>Character Ascension</h5>
        <StaticImage
          src="../../../images/eversoul/master/progression_4.webp"
          alt="Character Ascension"
        />
        <p>
          Character Ascension (dupe merging) is a core mechanic of Idle games;
          it allows you to combine copies/dupes of the same character to make
          them reach higher rarity and gain more stats. In the picture above,
          Jade is Origin +2 Stars, Yuria is Origin. Edith is Eternal+, Claire is
          Legendary+ and so on. Each Ascension not only adds stats to the soul,
          but initially you need them to bypass the level walls to unlock all
          your character skills. (Synced characters do not need to be ascended
          as they inherit levels directly). There are two primary walls in the
          game 161 wall - which requires you to get character to Legendary+ (4
          Copies for normal Souls, 6 Copies for Angel/Demon/Chaotic) and 201
          wall - which requires you to get Character to Origin (8 Copies for
          normal Souls, 14 Copies for Angel/Demon/Chaotic). Once you have
          achieved the same for 5 Souls, other souls inherit it via level sync,
          allowing them to access 201 upgrades even at Epic Ascension.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Ascension guide"
            link="/eversoul/guides/ascension"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_ascension.png"
                alt="Ascension guide"
              />
            }
          />
        </Row>
        <StaticImage
          src="../../../images/eversoul/master/progression_1.webp"
          alt="Keepsakes and Artifacts"
        />
        <h5>Keepsakes</h5>
        <p>
          Keepsakes (what gear is called in Eversoul) are another great way to
          increase your Souls stats. Eversoul has 4 Keepsake slots, and similar
          to characters, they also range from Epic to Origin+ in rarity, with
          each higher rarity having more flat as well as Set Bonus stats. There
          are 3 types of Keepsakes - INT, DEX or STR which can only be equipped
          by the corresponding type of souls. Keepsake functions can be divided
          into 2 parts - Stats and Set bonuses.
        </p>
        <p>
          Stats differ from piece to piece and per type, but they increase your
          various stats by the flat amounts or % mentioned on the piece. The
          flat stats increase as you increase the level of the Keepsake, though
          the % based increases remain static and only increase with Keepsake
          rarity. Set Bonuses (shown in bottom half of the Keepsake window) are
          additional bonuses characters gain when equipped with 2 or 4 pieces of
          the same set. It is important to note that the set bonus effects are
          additive, so in example above if you use 2 piece ATK set on your soul,
          you will get 10% Attack boost, whereas if you use 4 piece ATK set on
          your soul, you get 10%+15% for a total of 25% Attack boost. Thus, it
          is advisable to use 4 piece sets wherever possible instead of mixing
          2+2, as the stat gain is significantly more this way.
        </p>
        <p>
          Some of the important sets that you can never have too many of are -
          INT Speed Sets (used by some DPS and almost all Crowd Controllers and
          Supports, which are the main composition of most teams), DEX CDMG Sets
          (used by the majority of DEX DPS souls, as they excel in Single Target
          damage and have often high or guaranteed crits). STR characters don’t
          have any huge need for any specific set, though having a couple STR HP
          sets is helpful for some fight mechanics that require a certain
          character to have the highest HP, and STR Speed sets are beneficial
          for certain characters to rotate through their skills faster for
          higher buff uptime.
        </p>
        <h5>Artifacts</h5>
        <p>
          Artifacts are character exclusive equipment you can obtain from the
          Artifact gacha, Artifact Shop, Tower of Origin, and Rate Up banners
          (as a reward at certain pity levels). Artifacts form a very important
          part of a character’s strength as they can single-handedly change how
          a character is used and how strong it can be. Some characters can even
          drop multiple tiers in tierlist without them, or without having them
          at optimal Ascension investment. Similar to characters, Artifacts are
          also divided from Epic to Origin Ascension, with each level requiring
          more shards than the previous.
        </p>
        <ul>
          <li>Epic + Artifact = 100 Shards</li>
          <li>Legendary Artifact = 200 Shards</li>
          <li>Legendary + Artifact = 400 Shards</li>
          <li>Eternal Artifact = 700 Shards</li>
          <li>Eternal + Artifact = 1000 Shards</li>
          <li>Origin Artifact = 1500 Shards</li>
        </ul>
        <p>
          Thus you need a total of 3900 shards to raise a character's Artifact
          from Epic to Origin.
        </p>
        <p>
          <strong>
            Artifacts impact on characters can be further divided into 2 parts -
            Stats and Skill.
          </strong>
        </p>
        <p>
          The Stats refer to the four % Modifiers you see in first page, which
          increase different stats that vary from Artifact to Artifact, normally
          supplementing their main usage - for example, Ayame excels in DPS and
          Crowd Control, hence ATK and CDMG stats increase her DPS, while HP and
          Life Steal allow her to live longer. These stats can be gained by any
          soul who wears the Artifact, so for certain souls where their skill
          enhancement is not needed, they can wear another soul’s Artifact to
          gain important stats; to increase survivability, for example.
        </p>
        <p>
          The Artifact’s Skill is a unique skill that a character can gain by
          equipping their exclusive Artifact; this part is not transferable to
          any other character. These skills range from simple passive stat
          increases, to additional skill effects, and even changing entire kits
          sometimes, thus the optimal investment requirement can vary from
          Artifact to Artifact. Generally it's recommended to Ascend your main
          characters’ Artifacts to Legendary+, as it is the midpoint where the
          Artifact improves its main effect significantly, or gains another
          secondary effect. It is also extremely cost-effective to this point,
          while everything beyond becomes quite expensive for less return (until
          Origin). Once achieved, it is recommended to focus on certain few
          characters to max their artifacts before spreading yourself thin. The
          characters who are recommended to invest to Origin can be found in
          High Investment box/marked with $ sign in our Tierlist linked below.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/eversoul/tier-list"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tierlist.jpg"
                alt="Tier list"
              />
            }
          />
        </Row>
        <StaticImage
          src="../../../images/eversoul/master/progression_2.webp"
          alt="Formation Bonus, Soul Buffs and Soul Links"
        />
        <h5>Formation Bonus and Soul Buffs</h5>
        <p>
          During battle setup, you will notice two things that can further
          increase your Combat Power significantly and allow you to manipulate
          certain stats like Highest ATK and HP etc. That is Soul Bonus - where
          you can get various additional stat % across the team by utilizing the
          same types of souls or certain combos. Similarly formations can allow
          you to focus an increase of Attack or HP on certain souls, especially
          useful in raid content where you need to have certain characters
          achieve as much HP/DEF or ATK as possible. For more information and
          various bonuses available, refer to Team Building Guide linked below.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/eversoul/guides/team-building"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <h5>Soul Links</h5>
        <p>
          Soul Links can be found on the home screen under top right 3 Bars →
          Soulpedia → Soul Links. These links represent some of the character
          bonds in Lore, where raising all the souls in a link increases their
          stats - HP% and ATK% primarily to varying degrees depending on the
          link itself. Thus a nice bonus for many of the major souls. The links
          can range from having 2 to 4 souls, and the bonus is given according
          to lowest Ascension in the link, but in case the link includes Rare
          Soul, they will be only counted till Legendary +, which is their cap.
        </p>
        <h5>Town Manors</h5>
        <StaticImage
          src="../../../images/eversoul/master/progression_5.webp"
          alt="Town Manors"
        />
        <p>
          Town Manors, which can be purchased with Town Coins, are an essential
          part of increasing your soul’s stats such as ATK or HP or DEF and are
          one of the core methods to achieve stat manipulation during Guild
          Raids and ESS content where you need certain characters as highest ATK
          or HP to counter certain mechanics. They come in 3 Rarities - Small,
          Medium and Large and give different effects depending on it.
        </p>
        <ul>
          <li>Large Manors - 50k Town Coins - ATK +10%/ HP +10%/ DEF +12%</li>
          <li>Medium Manors - 10k Town Coins - ATK +5%/ HP +5%/ DEF +6%</li>
          <li>Small Manors - 1k Town Coins - ATK +3%/ HP +3%/ DEF +3.6%</li>
        </ul>
        <p>
          Each type of manor per stat can be bought 3 times, so you can have
          Total of 9 Large Manors (3 HP, 3 ATK, 3 DEF) and so on.
        </p>
        <p>
          Since at most you will be using 15 souls, it is advised to buy 1-2
          Small Manors to get started and then save for 9 Large Manors (in
          priority of ATK &gt; HP &gt; DEF), once done, buy Medium Manors
          according to your needs (mostly ATK and HP ones).
        </p>
        <StaticImage
          src="../../../images/eversoul/master/progression_3.webp"
          alt="Ark Enhance & Zodiac System"
        />
        <h5>Ark Enhance System</h5>
        <p>
          Introduced later into the game with Operation Eden Alliance, Ark
          Enhance system can be divided into two parts:
        </p>
        <ul>
          <li>
            Main Ark System - This increases stats of all your souls passively
            by the given amount as well as increase the level limit for the
            Overclock System. (For more details on Overclock System, refer to
            our Tips and Tricks Guide linked below).
          </li>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tips and tricks"
            link="/eversoul/guides/tips-and-tricks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tips.png"
                alt="Tips and trick"
              />
            }
          />
        </Row>
        <ul>
          <li>
            Role Enhancement System - These increase stats of all the souls
            within a specific role archetype, such as Warrior, Caster, etc.
            Unlike the Main Ark system which increases multiple stats, these are
            more focused, often increasing just 1 or 2 stats. They are %
            increases for important stats, allowing you to focus on certain
            types of souls; most DPS for raids are Casters, Strikers and
            Rangers, so you can go all in on them to increase your Raid DPS.
            Warriors, Casters, Rangers and Strikers increase 0.05% ATK per
            level, so for example Level 100 Ranger System would be a 5% Attack
            increase for all those souls. Similarly, Supporter increases 0.02%
            ATK and 0.04% HP per level and finally Defenders increase 0.02% DEF
            and 0.05% HP per level.
          </li>
        </ul>
        <h5>Zodiac Buffs</h5>
        <p>
          Zodiac Buffs are another method to permanently increase stats of your
          souls passively. The standard method for unlocking Zodiac Buffs was
          getting Aries, Cancer and Pisces for idle resources boost, and then
          using surplus on combat buffs initially. It all changed recently with
          the addition of Zodiac Presets, which allow you to save up to 10
          presets with different investments and blessings and switch between
          them for free! (Unlike the everstone fee you had to pay every time
          before to re-invest your points)
        </p>
        <p>
          This allows you to keep 1 preset for all the points in Aries, Pisces
          and Cancer for idle resource maximization that you use normally, while
          also making multiple other presets that are focused on increasing
          Stats and CP of your souls to push further in content or score higher,
          such as for raid centric preset . You can get nodes for commonly used
          characters such as DEX, INT, Humans, Fairies, Undead and Demons
          according to your team composition and then use blessings that
          increase CDMG or ATK further. You can have multiple presets for raids
          and other content, depending on soul types used, allowing you the
          freedom to change on the fly, compared to the cost it had before.
        </p>
        <h5>Bond Levels</h5>
        <StaticImage
          src="../../../images/eversoul/master/progression_6.webp"
          alt="Bond Stats"
        />
        <p>
          As mentioned earlier in the guide, the Bond System was recently
          revamped allowing it to go further than just a system for unlocking
          Love Stories, town bonuses and some lore. Starting from level 11 all
          the way to level 40, one of the 5 following stats are enhanced per
          level:
        </p>
        <ul>
          <li>Attack</li>
          <li>Defense</li>
          <li>HP</li>
          <li>Soul Rest Time</li>
          <li>Part Time Job Time</li>
        </ul>
        <p>
          While 2 of these stats are related to town content, the other 3 stats
          can allow you to invest in certain meta units (or your waifus!) to
          make them even stronger as they stack the additional stats.
        </p>
        <p>
          However, every additional level increases the amount of bond score by
          further 100 from previous one, making it a costly affair (example,
          Level 19→20 costs 2000 Bond Points, whereas Level 20→21 costs 2100
          Bond Points).
        </p>
        <p>
          While increasing bond levels, there are certain strategies and methods
          users can employ
        </p>
        <ul>
          <li>
            Equal distribution - As you can see in the picture below, the Stat
            increases are at the highest rate till Level 22 of Bond, where it
            increases by 1.2% every time the node is hit, compared to the later
            stages where it only gives 0.6% stats. Combined with the fact that
            every level increases the consumption of Bond points, it is
            significantly cheaper and more effective to raise multiple
            characters to Level 20 (The final 1.2% ATK node).
          </li>
          <li>
            Hyper Carry - Contrary to above, where you stop at Level 20/22 for
            maximum cost/gain ratio, if you are building a hyper carry for raid
            content and likewise, you would level the character to all the way
            to level 36 bond for the final attack node, resulting in 6% Attack
            Increase as well as 5.4% HP and DEF increase for the soul.
          </li>
          <li>
            Mah Waifu - Increase the bond level 40 to gain character exclusive
            profile stickers.
          </li>
        </ul>
        <SectionHeader title="Frequently Asked Questions" />
        <h5>How many servers will there be?</h5>
        <p>
          The game was initially split into 2 regions: NA/EU and Asia. Both are
          playable from the same client and there's no region block. So if you
          have friends in a region you don't live in, nothing stops you from
          playing with them. The latest Region to arrive is JP, which comes in
          its own separate install and is de-synced from the Main Servers (it
          has different banner & event schedules).
        </p>
        <h5>What are the supported languages?</h5>
        <p>
          English, Chinese (Traditional),Korean and Japanese. Voiceovers are
          available in Korean as well as Japanese (for souls released in JP
          server till date).
        </p>
        <h5>Is Eversoul a main or side type of game?</h5>
        <p>
          As most idle games, Eversoul should be a side game. While the first
          week or few will be more time-consuming, once you start hitting the
          progression walls, you will login, do daily quests and just wait for
          resources to gather.
        </p>
        <h5>How long does it take to finish the dailies?</h5>
        <p>Around 5-10 minutes.</p>
        <h5>What are the rates and pity?</h5>
        <p>
          4% for Epic Characters (including Angel, Demon and Chaotic Characters)
          and there's 30 pull mini-pity on the normal banner.
        </p>
        <h5>How important are dupes?</h5>
        <p>
          Very. Everything revolves around dupes in an idle game as the maximum
          level cap is tied to the rarity tier of your characters. Also not only
          the Epics are an issue, but also the fodder. This is why we suggest to
          pick one character from each of the 4 main factions and invest into
          it, and then pick a second character from the faction you have most
          dupes/fodder. This is the fastest way to progress in idle games.
        </p>
        <p>
          Keep in mind that because of the reliance on dupes, idle games have
          much higher Gem income than other gacha games so you pull way more
          often here.
        </p>
        <h5>When do I use my AFK currency consumables?</h5>
        <p>
          Whenever you get hard stuck pushing through Battlefront, and have no
          other way to accumulate currency, such as from Gates or Events.
          Hoarding all your currency items to use at a later stage isn’t worth
          as much as being able to push further in the content, as the Keepsake
          drops will increase in rarity as you push, and Battlefront is one of
          the few places to acquire them.
        </p>
        <h5>Is the game P2W?</h5>
        <p>
          It's not - as F2P player you can easily progress and you can also
          borrow strong heroes from your friends (up to 5 a day) to make your
          journey smoother.
        </p>
        <h5>Do you need to reroll?</h5>
        <p>
          You don’t have to, but it gives a considerable advantage in doing so
          and doesn't consume much time. Check out our guide below on how to
          reroll and optimal targets.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/eversoul/guides/reroll"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_reroll.jpg"
                alt="Reroll guide"
              />
            }
          />
        </Row>
        <h5>Is there a PC client?</h5>
        <p>
          There isn't one sadly. If you want to play the game on your PC, you
          can use emulators, but the developers are clear they won't support
          them so if any issues appear, you're on your own. However the game is
          available on Google Play Beta for Windows, which offers considerably
          higher performance than emulators.
        </p>
        <h5>Any other Tips to keep in mind?</h5>
        <p>Check out our Tips and Tricks guide linked below!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tips and tricks"
            link="/eversoul/guides/tips-and-tricks"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_tips.png"
                alt="Tips and trick"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesBeginnerGuide;

export const Head: React.FC = () => (
  <Seo
    title="Beginner's guide | Eversoul | Prydwen Institute"
    description="Beginner's guide to Eversoul."
    game="eversoul"
  />
);
